import React from "react";
import DatamapsIndia from "react-datamaps-india";
// Function to map state codes to state names
const stateCodeMap = {
    '01': 'Jammu & Kashmir',
    '02': 'Himachal Pradesh',
    '03': 'Punjab',
    '04': 'Chandigarh',
    '05': 'Uttarakhand',
    '06': 'Haryana',
    '07': 'Delhi',
    '08': 'Rajasthan',
    '09': 'Uttar Pradesh',
    '10': 'Bihar',
    '11': 'Sikkim',
    '12': 'Arunachal Pradesh',
    '13': 'Nagaland',
    '14': 'Manipur',
    '15': 'Mizoram',
    '16': 'Tripura',
    '17': 'Meghalaya',
    '18': 'Assam',
    '19': 'West Bengal',
    '20': 'Jharkhand',
    '21': 'Odisha',
    '22': 'Chhattisgarh',
    '23': 'Madhya Pradesh',
    '24': 'Gujarat',
    '27': 'Maharashtra',
    '29': 'Karnataka',
    '30': 'Goa',
    '31': 'Lakshadweep',
    '32': 'Kerala',
    '33': 'Tamil Nadu',
    '34': 'Puducherry',
    '35': 'Andaman & Nicobar Islands',
    '36': 'Telangana',
    '37': 'Andhra Pradesh',
    '38': 'Ladakh',
    '26': 'Dadra and Nagar Haveli and Daman and Diu',
    '97': 'Other Territory',
    '99': 'Centre Jurisdiction',
};
const MapChart = ({ gstinData = [] }) => {
    // Extract unique state codes from GSTIN data
    const stateCodes = new Set(gstinData.map(gstin => gstin.slice(0, 2)));
    // Map state codes to state names
    const highlightCities = Array.from(stateCodes).map(code => stateCodeMap[code]).filter(name => name);
    // Original region data
    const allRegionData = [
        { name: "Jammu & Kashmir", code: "01" },
        { name: "Himachal Pradesh", code: "02" },
        { name: "Punjab", code: "03" },
        { name: "Chandigarh", code: "04" },
        { name: "Uttarakhand", code: "05" },
        { name: "Haryana", code: "06" },
        { name: "Delhi", code: "07" },
        { name: "Rajasthan", code: "08" },
        { name: "Uttar Pradesh", code: "09" },
        { name: "Bihar", code: "10" },
        { name: "Sikkim", code: "11" },
        { name: "Arunachal Pradesh", code: "12" },
        { name: "Nagaland", code: "13" },
        { name: "Manipur", code: "14" },
        { name: "Mizoram", code: "15" },
        { name: "Tripura", code: "16" },
        { name: "Meghalaya", code: "17" },
        { name: "Assam", code: "18" },
        { name: "West Bengal", code: "19" },
        { name: "Jharkhand", code: "20" },
        { name: "Odisha", code: "21" },
        { name: "Chhattisgarh", code: "22" },
        { name: "Madhya Pradesh", code: "23" },
        { name: "Gujarat", code: "24" },
        { name: "Maharashtra", code: "27" },
        { name: "Karnataka", code: "29" },
        { name: "Goa", code: "30" },
        { name: "Lakshadweep", code: "31" },
        { name: "Kerala", code: "32" },
        { name: "Tamil Nadu", code: "33" },
        { name: "Puducherry", code: "34" },
        { name: "Andaman & Nicobar Islands", code: "35" },
        { name: "Telangana", code: "36" },
        { name: "Andhra Pradesh", code: "37" },
        { name: "Ladakh", code: "38" },
        { name: "Dadra and Nagar Haveli and Daman and Diu", code: "26" },
        { name: "Other Territory", code: "97" },
        { name: "Centre Jurisdiction", code: "99" },
    ];
    // Filter region data to only include the highlighted cities
    const filteredRegionData = allRegionData
        .filter(region => highlightCities.includes(region.name))
        .reduce((obj, region) => {
            obj[region.name] = { value: "", code: region.code }; // Set a default numerical value
            return obj;
        }, {});
  return (
    <div style={{ width: "500px", height: "300px",position:'relative', left:-29, }}>    
      <DatamapsIndia
        style={{width:'100%', left:0}}
        
        regionData={filteredRegionData}
        hoverComponent={({ value }) => {
          return (
            <div>
              <div>
                {value.name} (GST Code: {value.code})
              </div>
            </div>
          );
        }}
        mapLayout={{
          startColor: "#FFA500",
          endColor: "#FFA500",
          hoverTitle: "State",
          noDataColor: "#f5f5f5",
          borderColor: "#8D8D8D",
          hoverColor: "#FFA500",
          hoverBorderColor: "green",
        }}
      />
    </div>
  );
};
export default MapChart;
