import React, { useState } from "react";
import "./sidebar.css";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PersonIcon from '@mui/icons-material/Person';
import LaptopWindowsRoundedIcon from '@mui/icons-material/LaptopWindowsRounded';
import PercentRoundedIcon from "@mui/icons-material/PercentRounded";
import AirlinesRoundedIcon from "@mui/icons-material/AirlinesRounded";
import ImportExportRoundedIcon from "@mui/icons-material/ImportExportRounded";
import VpnKeyOutlinedIcon from "@mui/icons-material/VpnKeyOutlined";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import HotelOutlinedIcon from "@mui/icons-material/HotelOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import SportsTennisOutlinedIcon from "@mui/icons-material/SportsTennisOutlined";
import HandshakeOutlinedIcon from "@mui/icons-material/HandshakeOutlined";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import DataArrayIcon from "@mui/icons-material/DataArray";
import DownloadingIcon from '@mui/icons-material/Downloading';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import logo from '../../../assets/finkraft_logo.png'


const Sidebar = (props) => {
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  // const navigate = useNavigate();

  // Object consisting of all pages in your app and their respective links
  // const pages = {
  //   Login: "/",
  //   Home: "/home",
  //   Onboarding1: "/onboarding1",
  //   Onboarding2: "/onboarding2",
  //   "GST Credentials": "/onboarding3",
  //   "Scraper History": "/scraperhistory",
  //   Returns_Home: "/returns_home",
  //   "IRN-2B": "/joined",
  //   "Column Config": "/colcon",
  //   "Column Group Builder": "/colgroup",
  //   "ag-col": "/agcol",
  //   "ag-col2": "/agcol2",
  //   cluster: "/cluster",
  //   workspace: "/workspace",
  //   "Airline Credentials": "./air",
  //   "Lufthansa Credentials": "./luf",
  //   "Credentials Bulk Upload": "./bulkupload",
  //   "FlightMMT":"./mmt",
  //   "reconmmt":"./reconmmt",
  //   "WorkspacePan":"./WorkspacePan"
  // };
  return (
    <div className="Sidebar">
      <div className="topImage">
        <img style={{width:'40px', height:'40px'}} src={logo} />
      </div>
      <div className="MenuList">
        {/* <div
          className={
            "MenuItem " + (props.activeMenu === "creds" ? "activeMenuItem" : "")
          }
          // onClick={() => props.onChange("creds")}
        >
          <HomeOutlinedIcon style={{ fontSize: 28 }} />
        </div> */}

        {/* <div
          className={
            "MenuItem " +
            (props.activeMenu === "playground" ? "activeMenuItem" : "")
          }
          // onClick={() => props.onChange("playground")}
        >
          <SportsTennisOutlinedIcon style={{ fontSize: 28 }} />
        </div>

        <div
          className={
            "MenuItem " +
            (props.activeMenu === "recon_air" ? "activeMenuItem" : "")
          }
          // onClick={() => props.onChange("recon_air")}
        >
          <AirlinesRoundedIcon style={{ fontSize: 28 }} />
        </div>


        <div
          className={
            "MenuItem " +
            (props.activeMenu === "parser" ? "activeMenuItem" : "")
          }
          // onClick={() => props.onChange("parser")}
        >
          <DocumentScannerOutlinedIcon style={{ fontSize: 28 }} />
        </div>
        <div
          className={
            "MenuItem " +
            (props.activeMenu === "automail" ? "activeMenuItem" : "")
          }
          // onClick={() => props.onChange("automail")}
        >
          <EmailOutlinedIcon style={{ fontSize: 28 }} />
        </div>
        <div
          className={
            "MenuItem " +
            (props.activeMenu === "FlightMMT" ? "activeMenuItem" : "")
          }
          // onClick={() => props.onChange("FlightMMT")}
        >
          <VpnKeyOutlinedIcon style={{ fontSize: 28 }} />
        </div>
        <div
          className={
            "MenuItem " +
            (props.activeMenu === "reconmmt" ? "activeMenuItem" : "")
          }
          // onClick={() => props.onChange("reconmmt")}
        >
          <DocumentScannerOutlinedIcon style={{ fontSize: 28 }} />
        </div> */}
      </div>
    </div>
  );
};

export default Sidebar;
