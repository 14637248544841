import React, { useState } from 'react';
import { Modal, Button, Input, message } from 'antd';
import './GstModal.css'; // Import the CSS file
import axios from 'axios';

const GstModal = ({ visible, onClose, gstin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleAuthenticate = async () => {
    try {
      const response = await axios.post('https://vendor-score.finkraftai.com/api/addcreds', {
        Gstin: gstin,
        Username: username,
        Password: password,
      });

      if (response.status === 200) {
        message.success('Authentication successful!');
        alert('Authentication successful!')
      }
    } catch (error) {
      message.error('Authentication failed. Please try again.');
      alert('Authentication failed. Please try again.')
    }
  };

  return (
    <Modal
      title="GST Portal Integration"
      visible={visible}
      onCancel={onClose}
      footer={null}
      maskClosable={false}
      className="custom-modal"
    >
      <div className="AddIntegration">
        <div className="IntegrationImage">
          <img 
            src="https://miro.medium.com/v2/resize:fit:1400/1*9mkbjH3YUUGDSWk2UXFghw.png" 
            alt="integrationImg" 
            style={{ width: 44 }} 
          />
          <p>GST Portal Authentication</p>
        </div>
        <div>
          <p>Selected GSTIN: {gstin}</p> {/* Display the selected GSTIN */}
        </div>
        <div className="FormBody">
          <div className="FormGroup">
            <Input 
              placeholder="Username" 
              style={{ height: 42, width: '100%' }} 
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="FormGroup">
            <Input 
              placeholder="Password" 
              type="password" 
              style={{ height: 42, width: '100%' }} 
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="FormGroup">
            <Button 
              type="primary" 
              style={{ width: '100%', background: 'rgb(10, 131, 148)', color: 'white' }}
              onClick={handleAuthenticate}
            >
              Authenticate
            </Button>
          </div>
        </div>
        <div className="footer-link">
          <p>Or setup bulk integrations</p>
        </div>
      </div>
    </Modal>
  );
};

export default GstModal;
