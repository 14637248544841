import React from 'react';
import { AgCharts } from 'ag-charts-react';

const BarChart1 = ({ filingData }) => {
  // Access the correct nested object and handle possible undefined or null values
  const performanceData = filingData?.["GSTR3B"] || {};

  // Get all months sorted in reverse order
  const getSortedMonths = () => {
    const months = Object.keys(performanceData);
    return months.sort((a, b) => new Date(b + "-01") - new Date(a + "-01"));
  };

  const formatChartData = () => {
    const months = getSortedMonths();
    const data = months.map(month => {
      const status = String(performanceData[month]); // Ensure status is a string
      return {
        month,
        filedOnTime: status.includes('Filed On Time') ? parseInt(status.match(/\d+/)[0]) : 0,
        filedDelayed: status.includes('Delayed') ? parseInt(status.match(/\d+/)[0]) : 0,
      };
    });
    return data;
  };

  const chartData = formatChartData();

  const options = {
    // title: {
    //   text: "Recent Filing Performance GSTR3B",
    //   fontSize: 18,
    //   fontWeight: 'bold',
    //   fontFamily: 'Arial, sans-serif',
    //   color:'#0A8394'
    // },
    data: chartData,
    series: [
      {
        type: "bar",
        xKey: "month",
        yKey: "filedOnTime",
        yName: "Filed on Time",
        stacked: false,
        fill: "#0a8394", // Green for on time
        cornerRadius: 0,
      },
      {
        type: "bar",
        xKey: "month",
        yKey: "filedDelayed",
        yName: "Filed Delayed",
        stacked: false,
        fill: "#e5e8f5", // Grey for delayed
        cornerRadius: 0,
      },
    ],
    axes: [
      {
        type: "category",
        position: "bottom",
        label: {
          rotation: 0,
          fontSize: 14,
          fontFamily: 'Arial, sans-serif',
          color: '#333',
        },
        tick: {
          count: 12,
        },
        paddingInner: 0.7,
      },
      {
        type: "number",
        position: "left",
        title: {
          text: "Filing Date",
          fontSize: 14,
          fontFamily: 'Arial, sans-serif',
          color: '#333',
        },
        tick: {
          count: 6,
        },
        max: 30, // Adjust this according to the maximum delay
        label: {
          fontSize: 12,
          fontFamily: 'Arial, sans-serif',
          color: '#333',
        },
      },
    ],
    legend: {
      position: "bottom",
      item: {
        label: {
          fontSize: 14,
          fontFamily: 'Arial, sans-serif',
          color: '#333',
        },
        marker: {
          shape: 'circle',
          size: 14,
        },
      },
    },
    padding: {
      top: 20,
      right: 20,
      bottom: 40,
      left: 40,
    },
  };
  
  const chartStyle = {
    height: '450px', 
    width: '850px', 
  };

  return (
    <div>
      <h2 style={{color:'hsla(187, 87%, 31%, 1)',padding:'20px 40px'}}>Recent Filing Performance GSTR3B</h2>
      <AgCharts options={options} style={chartStyle} />
    </div>
  );
};

export default BarChart1;
