import React, { useState, useEffect } from "react";
import { AgCharts } from "ag-charts-react";
import { Padding } from "@mui/icons-material";

const ChartExample = ({ prevDtls }) => {
  const [options, setOptions] = useState({
    // title: {
    //   text: "% of Liabilites paid ",
    //   fontSize: 18,
    //   fontWeight: 'bold',
    //   fontFamily: 'Arial, sans-serif',
    //   color:'#0A8394',
    //   paddingLeft:'100px'
    // },
    data: [],  // Initialize as an empty array
    series: [
      {
        type: "line",
        xKey: "taxperiod",  // Use taxperiod for xKey
        xName: "Tax Period",
        yKey: "liab_pct",   // Use liab_pct for yKey
        yName: "Liability Percentage",
        stroke: "#0a8394",  // Set the line color
        strokeWidth: 3,
        interpolation: { type: "line" },
        marker: {
          enabled: true,  // Disable markers by default
          size: 10,
          fill: 'white',  // Set the marker fill color
          stroke: "#0a8394",  // Set the marker stroke color
          strokeWidth: 2,
        },
        highlightStyle: {
          item: {
            enabled: true,  // Show the marker when hovering
            fill: '#0a8394',
            stroke: '#0a8394',
            strokeWidth: 3,
            size: 10,  // Marker size when hovered
          },
        },
        tooltip: {
          enabled: true,
          renderer: (params) => {
            return {
              content: `${params.datum.taxperiod}: ${params.datum.liab_pct}%`,
            };  // Display the correct percentage with the tax period
          },
        },
        fillOpacity: 0.3,
      },
    ],
  });

  useEffect(() => {
    if (prevDtls) {
      const allPeriods = [
        'Apr-23', 'May-23', 'Jun-23', 'Jul-23', 'Aug-23', 'Sep-23', 
        'Oct-23', 'Nov-23', 'Dec-23', 'Jan-24', 'Feb-24', 'Mar-24'
      ];

      // Ensure we have exactly 12 items
      let data = [...prevDtls];

      // Convert existing data to a map for easier lookup
      const dataMap = new Map(data.map(item => [item.taxperiod, item]));

      // Create a new array with all periods, filling missing ones with null
      data = allPeriods.map(period => ({
        taxperiod: period,
        liab_pct: dataMap.get(period)?.liab_pct ?? null  // Use null if not found
      }));

      setOptions(prevOptions => ({
        ...prevOptions,
        data: data.map(item => ({
          taxperiod: item.taxperiod,
          liab_pct: item.liab_pct !== null ? item.liab_pct : 0  // Use 0 if liab_pct is null
        })),
      }));
    }
  }, [prevDtls]);

  const chartStyle = {
    height: '350px', 
    width: '780px', 
    paddingLeft:'30px'
  };

  return (
    <div>
      <h2 style={{color:'hsla(187, 87%, 31%, 1)',padding:'20px 50px'}}>% of Liabilites paid</h2>
      <AgCharts options={options} style={chartStyle} />
    </div>
  );
};

export default ChartExample;
