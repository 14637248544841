import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './table.css';
import { Flex } from 'antd';

function CurrentScore({ gstinDetails }) {
  const columnDefs = [
    { headerName: 'Score Type', field: 'scoreType', resizable: true, sortable: true,Flex:1,width:'300px' },
    { headerName: 'Score', field: 'score', resizable: true, sortable: true,Flex:1 }
  ];



  const rowData = [
    {
      scoreType: 'GSTR1 Current Weightage',
      score: gstinDetails?.gstr1_current_weightage ? gstinDetails.gstr1_current_weightage.toFixed(2) : '0',
      weightage: '30%',
    },
    {
      scoreType: 'GSTR1 Previous Weightage',
      score: gstinDetails?.gstr1_previous_weightage ? gstinDetails.gstr1_previous_weightage.toFixed(2) : '0',
      weightage: '30%'
    },
    {
      scoreType: 'GSTR3B Recent Weightage',
      score: gstinDetails?.gstr3b_recent_weightage ? gstinDetails.gstr3b_recent_weightage.toFixed(2) : '0',
      weightage: '20%'
    },
    {
      scoreType: 'GSTR3B Previous Weightage',
      score: gstinDetails?.gstr3b_previous_weightage ? gstinDetails.gstr3b_previous_weightage.toFixed(2) : '0',
      weightage: '10%'
    },
    {
      scoreType: 'Liability Payment Score',
      score: gstinDetails?.liability_payment_score ? gstinDetails.liability_payment_score.toFixed(2) : '0',
      weightage: '10%'
    },
  ];


  return (
    <div className="container1">
      <h2 className='table-head' style={{color:'hsla(187, 87%, 31%, 1)'}}>Current Scores</h2>
      <div className="header-line"></div>
      <div className="ag-theme-alpine" style={{height: 300, width: '100%'}}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          domLayout="autoHeight"
          headerHeight={50}
          rowHeight={50}
          suppressCellSelection={true}
          
        />
      </div>
    </div>
  );
}

export default CurrentScore;
