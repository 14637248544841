import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './table.css';

function HSNCode({ hsnData }) {
  const columnDefs = [
    { headerName: 'HSN Code', field: 'hsncd', resizable: true, sortable: true },
    { headerName: 'Description', field: 'gdes', resizable: true, sortable: true, width:'2000px' },
  ];

  // Use the hsnData prop to populate the rowData
  const rowData = hsnData.map(item => ({
    hsncd: item.hsncd,
    gdes: item.gdes
  }));

  return (
    <div className="container3">
      <h2 className='table-head' style={{color:'hsla(187, 87%, 31%, 1)'}}>HSN Codes</h2>
      <div className="header-line"></div>
      <div className="ag-theme-alpine" style={{ width: '100%'}}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          domLayout="autoHeight"
          headerHeight={50}
          rowHeight={40}
          suppressCellSelection={true}
        />
      </div>
    </div>
  );
}

export default HSNCode;
