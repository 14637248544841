import React, { useRef, useEffect } from 'react';
import './meter.css'
import Piechart from '../VendorCard/PieChart';

const SimpleGauge = ({ totalWeightage=0, legalName, }) => {
    const containerRef = useRef(null);
    const baseRef = useRef(null);
    const progressRefs = useRef([]);
    const pointBorderRef = useRef(null);  // Add a reference for the border circle

    useEffect(() => {
        const container = containerRef.current;
        const widthValue = container.clientWidth;

        const strokeWidth = widthValue / 13;
        const height = (widthValue / 2) + (strokeWidth / 2);
        const ratio = (widthValue - strokeWidth) / 2.1;
        const offset = Math.PI * ratio;

        let validPercent = totalWeightage >= 0 ? (totalWeightage <= 100 ? totalWeightage : 100) : 0;
        const segment = offset / 4; // Divide the gauge into 4 segments

        const colors = ['#F06B4B','#F5C956', '#48C497','#48C497']; 

        container.style.height = `${height}px`;

        const baseCircle = baseRef.current;
        baseCircle.style.width = '100%';
        baseCircle.style.height = '100%';
        baseCircle.style.fill = 'none';
        baseCircle.style.stroke = '#E5E5E5';
        baseCircle.style.strokeLinecap = 'round';
        baseCircle.setAttribute('cx', ratio);
        baseCircle.setAttribute('cy', ratio);
        baseCircle.setAttribute('r', ratio);
        baseCircle.style.strokeDashoffset = offset;
        baseCircle.style.strokeDasharray = offset;
        baseCircle.style.strokeWidth = strokeWidth.toString();
        baseCircle.style.transform = `translate(${strokeWidth / 2}px,${strokeWidth / 2}px)`;

        colors.forEach((color, idx) => {
            const progressCircle = progressRefs.current[idx];
            progressCircle.style.width = '100%';
            progressCircle.style.height = '100%';
            progressCircle.style.fill = 'none';
            progressCircle.style.stroke = color;
            progressCircle.style.strokeLinecap = 'round';
            progressCircle.setAttribute('cx', ratio);
            progressCircle.setAttribute('cy', ratio);
            progressCircle.setAttribute('r', ratio);
            progressCircle.style.strokeDashoffset = offset - (segment * idx);
            progressCircle.style.strokeWidth = strokeWidth.toString();
            progressCircle.style.transform = `translate(${strokeWidth / 2}px,${strokeWidth / 2}px)`;
            progressCircle.style.strokeDasharray = `${segment} ${offset * 2 - segment}`;
        });

        const pointBorderCircle = pointBorderRef.current; // Reference to the border circle
        pointBorderCircle.style.width = '100%';
        pointBorderCircle.style.height = '100%';
        pointBorderCircle.style.fill = 'none';
        pointBorderCircle.style.stroke = 'white'; // Set the color of the border to white
        pointBorderCircle.style.strokeLinecap = 'round';
        pointBorderCircle.setAttribute('cx', ratio);
        pointBorderCircle.setAttribute('cy', ratio);
        pointBorderCircle.setAttribute('r', ratio);
        pointBorderCircle.style.strokeWidth = (strokeWidth + 12).toString(); // Increase the size for a larger border
        pointBorderCircle.style.transform = `translate(${strokeWidth / 2}px,${strokeWidth / 2}px)`;
        pointBorderCircle.style.strokeDasharray = `1 ${(offset * 2) - 1}`;
        pointBorderCircle.style.strokeDashoffset = offset - (validPercent * offset / 100);

        const pointCircle = progressRefs.current[4];
        pointCircle.style.width = '100%';
        pointCircle.style.height = '100%';
        pointCircle.style.fill = 'none'; // Ensure the point circle is transparent
        pointCircle.style.strokeLinecap = 'round';
        pointCircle.setAttribute('cx', ratio);
        pointCircle.setAttribute('cy', ratio);
        pointCircle.setAttribute('r', ratio);
        pointCircle.style.strokeWidth = (strokeWidth + 6).toString(); // Increase the size for the point circle

        // Determine the color based on the percent value
        let pointColor;
        if (totalWeightage < 25) {
            pointColor = '#F06B4B'; // Red
        } else if (totalWeightage < 50) {
            pointColor = '#F5C956'; // Yellow
        }else if(totalWeightage < 100) {
            pointColor = '#48C497'; // Green
        }
        else {
            pointColor = '#F06B4B'; // Red
        }

        pointCircle.style.stroke = pointColor; // Set the color of the point circle based on the percent value
        pointCircle.style.transform = `translate(${strokeWidth / 2}px,${strokeWidth / 2}px)`;
        pointCircle.style.strokeDasharray = `1 ${(offset * 2) - 1}`;
        pointCircle.style.strokeDashoffset = offset - (validPercent * offset / 100);

        const textElement = containerRef.current.querySelector('.number');
        textElement.style.fontSize = `${height / 3}px`;
        textElement.style.fontWeight = 'bold';
        textElement.style.marginTop = `-${height / 2}px`;
        textElement.style.color = 'black';
        textElement.style.display = 'grid';
        textElement.style.justifyContent = 'center';
    }, [totalWeightage]);

    return (
        <div>
            <div className='topside'>
                <div>
             <p style={{ width:'300px', color:'#0A8394' }}>{legalName}</p>
             <div className="header-line1"></div></div>
             <div className='svg-container'>
             <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
  <path d="M8.4375 10.7812H7.96875V6.59156C7.96875 6.58641 7.96734 6.58172 7.96734 6.57703C7.96734 6.57234 7.96875 6.56766 7.96875 6.5625C7.96875 6.30375 7.75875 6.09375 7.5 6.09375H6.5625C6.30375 6.09375 6.09375 6.30375 6.09375 6.5625C6.09375 6.82125 6.30375 7.03125 6.5625 7.03125H7.03125V10.7812H6.5625C6.30375 10.7812 6.09375 10.9913 6.09375 11.25C6.09375 11.5087 6.30375 11.7188 6.5625 11.7188H8.4375C8.69625 11.7188 8.90625 11.5087 8.90625 11.25C8.90625 10.9913 8.69625 10.7812 8.4375 10.7812ZM7.5 5.15625C8.01797 5.15625 8.4375 4.73625 8.4375 4.21875C8.4375 3.70125 8.01797 3.28125 7.5 3.28125C6.98203 3.28125 6.5625 3.70125 6.5625 4.21875C6.5625 4.73625 6.9825 5.15625 7.5 5.15625ZM7.5 0C3.35812 0 0 3.35766 0 7.5C0 11.6423 3.35766 15 7.5 15C11.6423 15 15 11.6423 15 7.5C15 3.35766 11.6423 0 7.5 0ZM7.5 14.077C3.88125 14.077 0.9375 11.1183 0.9375 7.49953C0.9375 3.88078 3.88125 0.937031 7.5 0.937031C11.1187 0.937031 14.0625 3.88078 14.0625 7.49953C14.0625 11.1183 11.1187 14.077 7.5 14.077Z" fill="#9291A5"/>
</svg>
<div class="popup">
    <Piechart/>
  </div>
</div>
</div>

        <div className='gaugemeter' style={{display:"flex" , flexDirection:'column'}}>
            <div style={{ position: 'relative'}} ref={containerRef}>
                <svg className="gauge-svg">
                    <circle ref={baseRef}></circle>
                    {[...Array(4)].map((_, i) => (
                        <circle ref={el => progressRefs.current[i] = el} key={i}></circle>
                    ))}
                    <circle ref={pointBorderRef}></circle> {/* Border circle for the point indicator */}
                    <circle ref={el => progressRefs.current[4] = el}></circle> {/* Point indicator */}
                </svg>
                <div className="number" style={{ textAlign: 'center',position: 'absolute', top: '150px', left: '34%',}}>{totalWeightage.toFixed(2)}</div>
                <div style={{ position: 'absolute', top: '130px', left: '50%', transform: 'translateX(-50%)', fontSize: '16px' }}>
                    Vendor Score
                </div>
            </div>
        </div>
        </div>
    );
};

export default SimpleGauge;

