import React, { useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './table1.css';
import GstModal from './GstModal'; 

// Function to map state codes to state names
const stateMap = {
  '01': 'Jammu & Kashmir',
  '02': 'Himachal Pradesh',
  '03': 'Punjab',
  '04': 'Chandigarh',
  '05': 'Uttarakhand',
  '06': 'Haryana',
  '07': 'Delhi',
  '08': 'Rajasthan',
  '09': 'Uttar Pradesh',
  '10': 'Bihar',
  '11': 'Sikkim',
  '12': 'Arunachal Pradesh',
  '13': 'Nagaland',
  '14': 'Manipur',
  '15': 'Mizoram',
  '16': 'Tripura',
  '17': 'Meghalaya',
  '18': 'Assam',
  '19': 'West Bengal',
  '20': 'Jharkhand',
  '21': 'Odisha',
  '22': 'Chhattisgarh',
  '23': 'Madhya Pradesh',
  '24': 'Gujarat',
  '27': 'Maharashtra',
  '29': 'Karnataka',
  '30': 'Goa',
  '31': 'Lakshadweep',
  '32': 'Kerala',
  '33': 'Tamil Nadu',
  '34': 'Puducherry',
  '35': 'Andaman & Nicobar Islands',
  '36': 'Telangana',
  '37': 'Andhra Pradesh',
  '38': 'Ladakh',
  '26': 'Dadra and Nagar Haveli and Daman and Diu',
  '97': 'Other Territory',
  '99': 'Centre Jurisdiction',
};

const GSTTable = ({ gstinData }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGstin, setSelectedGstin] = useState(null); // State for selected GSTIN

  // Map GSTINs to states using the stateMap
  const rowData = gstinData.map(gstin => ({
    gstin,
    state: stateMap[gstin.slice(0, 2)] || 'Unknown'
  }));

  const columnDefs = [
    { headerName: 'GSTIN Number', field: 'gstin', resizable: true, sortable: true, flex: 1 },
    { headerName: 'State', field: 'state', resizable: true, sortable: true, flex: 1 }
  ];

  // Handle row click
  const onRowClicked = (params) => {
    setSelectedGstin(params.data.gstin);
    setIsModalVisible(true);
  };

  return (
    <div className="container">
      <div className="ag-theme-alpine" style={{ height: 350, width: '100%', overflowY: 'auto' }}>
        <AgGridReact
          columnDefs={columnDefs}
          rowData={rowData}
          domLayout="autoHeight"
          headerHeight={50}
          rowHeight={80}
          suppressCellSelection={true}
          className="ag-grid-container"
          onRowClicked={onRowClicked} 
        />
      </div>
      <GstModal 
        visible={isModalVisible} 
        onClose={() => setIsModalVisible(false)} 
        gstin={selectedGstin} // Pass selected GSTIN to modal
      />
    </div>
  );
};

export default GSTTable;