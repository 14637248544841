import React from 'react';
import './BlankCard.css';

export default function BlankCard({ id, component: Component, ...props }) {
  return (
    <div className='blank-card' id={id}>
      {/* Pass down any additional props to the Component */}
      {Component && <Component {...props} />}
    </div>
  );
}
